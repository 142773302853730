import { doQuery } from "@/api/core"
import CmyPageOld from '@/components/cmy-page/cmy-page-old.vue'
import DaliySign from '@/components/daliy-sign/daliy-sign.vue'
import StudyRankData from '@/components/study-rank-data/study-rank-data.vue'
import { mapGetters, mapMutations } from 'vuex'
import VueHoverMask from '@/components/hover-mask'
import dateUtils from '@/utils/dateUtils'
import util from "../../../utils/cmyUtils"

export default {
    name: "task",
    components: {
        CmyPageOld,
        DaliySign,
        StudyRankData,
        VueHoverMask
    },
    computed: {
        ...mapGetters([
            'trainingId',
            'trainingVisible',
            'seriesId',
            'seriesVisible',
        ])
    },
    data() {
        return {
            //当前
            currentTime: null,
            timeHandler: null,

            activeMenuIndex: 0,
            tasks: [],
            task_loading: false,

            daliySignTaskId: undefined,
            studyRankTaskId: undefined,

            pagination: {
                page: 1,
                limit: 18,
                end: false
            },
            hide: null,
        }
    },
    mounted() {

        let that = this;
        that.updateCurrentTime();

        //定期更新当前时间
        that.timeHandler = setInterval(function() {
            that.updateCurrentTime();
        }, 60000);

        this.hide = this.$message.loading('正在加载中...', 0)
        this.queryTasks()
    },

    beforeDestroy() {

        //清除定时器
        const that = this
        if (that.timeHandler) {
            clearInterval(that.timeHandler);
            that.timeHandler = null;
        }

    },

    methods: {

        updateCurrentTime() {
            let that = this;

            //更新当前时间，用于判断是否可以开始
            that.currentTime = dateUtils.getNow();

            //更新任务的截止时间
            if (that.tasks && that.tasks.length > 0){

                that.tasks.forEach(function(item,index){

                    if (item.deadline_time){
                        item.deadmins = util.getDateDiffMinutes(item.deadline_time);
                        item.deadminsFormat = util.formatMinutesEx(item.deadmins);
                    }

                })
            }

            console.log(that.tasks)
        },

        ...mapMutations([
            'SET_TRAINING_ID',
            'SET_TRAINING_VISIBLE',
            'SET_SERIES_ID',
            'SET_SERIES_VISIBLE'
        ]),
        changeActiveMenuIndex(index) {
            const that = this
            that.activeMenuIndex = index
            that.queryTasks(1,that.pagination.limit)
        },

        queryTasks(page = this.pagination.page, pageSize = this.pagination.limit) {
            const that = this
            const { activeMenuIndex } = that
            doQuery({
                query_code: 'LN100',
                param_str1: (activeMenuIndex == 0 ? '' : (activeMenuIndex == 1 ? '0' : '1')),
                page: page,
                limit: pageSize
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {

                    that.tasks = res.data

                    that.pagination.page = page
                    that.pagination.limit = pageSize
                    that.pagination.end = res.data.length < pageSize ? true : false
                    res.data.forEach((item, index) => {
                        that.queryTaskItems(index,page,pageSize)
                    })

                    that.tasks.forEach((item, index) => {

                        //格式化剩余时间
                        item.deadminsFormat = util.formatMinutesEx(item.deadmins);
                    })

                }
            }).finally(() => {
                setTimeout(that.hide(), 1000)
            })
        },
        queryTaskItems(index,page = this.pagination.page, pageSize = this.pagination.limit) {
            const that = this
            const { tasks } = that
            that.task_loading = true
            if(tasks[index].tasktype_code == that.$consts.biz.TASK_TYPES.COMP) {
                doQuery({
                    query_code: 'LN110',
                    param_str2: tasks[index].task_id,
                    page: page,
                    limit: pageSize
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.$set(that.tasks[index], 'taskitems', res.data);
                    }else {
                        that.$set(that.tasks[index], 'taskitems', []);
                    }
                    that.task_loading = false
                })
            }else {
                that.task_loading = false
            }
        },
        toDoTask(index) {
            const that = this
            var task = that.tasks[index]

            //如果超时，而且不允许超时完成，则禁止
            if (task.allow_delay == '0' && task.deadline_time < that.currentTime ) {

                that.$message.error('该任务已截止')
                return;

            }

            //如果超时，而且不允许超时完成，则禁止
            if (task.start_time > that.currentTime ) {

                that.$message.error('该任务未开始')
                return;

            }

            //如果是调查问卷
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.INVEST) {
                that.$router.push({path: '/student/questionaire?taskId=' + task.task_id })
            }
            //如果是直接考试
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.EXAM) {
                that.$router.push({path: '/student/examination?taskId=' + task.task_id })
            }
            //如果是单次课程培训
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.LESSON || task.tasktype_code == that.$consts.biz.TASK_TYPES.SELF) {
                that.$router.push({path: '/student/singleLesson?taskId=' + task.task_id})
            }
            //如果是系列课程培训
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.SERIES) {
                if(that.seriesId && that.seriesId == task.task_id) {
                    that.SET_SERIES_VISIBLE(!that.seriesVisible)
                }else {
                    that.SET_TRAINING_ID(null)
                    that.SET_SERIES_ID(task.task_id)
                }
            }
            //如果是训练营程培训
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.TRAIN) {
                if(that.trainingId && that.trainingId == task.task_id) {
                    that.SET_TRAINING_VISIBLE(!that.trainingVisible)
                }else {
                    that.SET_SERIES_ID(null)
                    that.SET_TRAINING_ID(task.task_id)
                }
            }

        },
        doDaliySign(taskId) {
            const that = this
            that.daliySignTaskId = taskId
        },
        showStudyRankData(taskId) {
            const that = this
            that.studyRankTaskId = taskId
        }
    }
}
